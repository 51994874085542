import ClientCarousel01 from "../images/info/1.svg";
import ClientCarousel02 from "../images/info/2.svg";
import ClientCarousel03 from "../images/info/3.svg";
import ClientCarousel04 from "../images/info/4.svg";
import ClientCarousel05 from "../images/info/5.svg";
import ClientCarousel06 from "../images/info/6.svg";
import ClientCarousel07 from "../images/info/7.svg";
import teacher1 from "../images/professores/teacher-1.png";
import teacher2 from "../images/professores/teacher-2.png";
import teacher3 from "../images/professores/teacher-3.png";
import teacher4 from "../images/professores/teacher-4.png";
import teacher5 from "../images/professores/teacher-5.png";
import teacher6 from "../images/professores/teacher-6.png";

export const NavLinks = [
  {
    name: "Ensino Médio",
    url: "/#ensino-medio",
  },
  {
    name: "Pré-Vestibular",
    url: "/#ensino-medio",
  },
  {
    name: "O que é alta performance?",
    url: "/#alta-perfomance",
  },
  {
    name: "Só o Bertoni tem",
    url: "/#bertoni-tem",
  },
  {
    name: "Quero saber mais",
    url: "/#contatos",
  },
];

export const ContactInfosBlock = {
  subTitle: "our offices",
  title: "offices near you",
  description:
    "We are committed to providing our customers with exceptional service while \n offering our employees the best training.",
};

export const ContactInfosList = [
  {
    title: "austin",
    infos: [
      {
        name: "22 Texas West Hills",
      },
      {
        name: "needhelp@meipaly.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
  {
    title: "boston",
    infos: [
      {
        name: "5 Federal Street Boston",
      },
      {
        name: "needhelp@meipaly.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
  {
    title: "new york",
    infos: [
      {
        name: "8th Broklyn New York",
      },
      {
        name: "needhelp@meipaly.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
  {
    title: "baltimore",
    infos: [
      {
        name: "3 Lombabr 50 baltimore",
      },
      {
        name: "needhelp@meipaly.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
];

export const ContactFormTitle = {
  subTitle: "Contact with us",
  title: "write us a message",
  description:
    "We are committed to providing our customers with exceptional service while \n      offering our employees the best training. ",
};

export const BlogHomeSection = {
  subTitle: "our news & articles",
  title: "latest blog posts",
  text:
    "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
};

export const PortfolioFilters = [
  { name: "all" },
  { name: "graphic" },
  { name: "branding" },
  { name: "marketing" },
  { name: "logos" },
];

export const ServiceHomeTwoData = {
  subTitle: "welcome to smart meipaly web agency",
  title: "We design digital products that \n help grow businesses.",
  text:
    "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
};

export const ServiceHomeThreeData = {
  subTitle: "Services we are offering",
  title: "Our Services",
  text:
    "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
};

export const FunfactData = [
  {
    title: "Projects Completed",
    countNumber: 705,
  },
  {
    title: "Active Clients",
    countNumber: 480,
  },
  {
    title: "Cups of Coffee",
    countNumber: 626,
  },
  {
    title: "Happy Clients",
    countNumber: 774,
  },
];

export const TeamOneData = {
  sectionContent: {
    title: "expert people",
  },
  posts: [
    {
      image: teacher1,
    },
    {
      image: teacher2,
    },
    {
      image: teacher3,
    },
    {
      image: teacher4,
    },
    {
      image: teacher5,
    },
    {
      image: teacher6,
    },
  ],
};

export const SubscribeFormData = {
  sectionContent: {
    title: "Subscribe us",
    subTitle: "don’t miss out our latest updates",
  },
};

export const PortfolioHomeData = {
  sectionContent: {
    title: "work showcase",
    subTitle: "our portfolio",
    text:
      "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
  },
};

export const VideoTwoData = {
  sectionContent: {
    title: "VENHA ESTUDAR COM A GENTE E TENHA ACESSO ÀS MELHORES UNIVERSIDADES DO PAÍS E DO MUNDO.",
    // subTitle: "how do we works",
    // text:
    //   "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
  },
  video: {
    title: "Watch Video",
    ID: "y2Eqx6ys1hQ",
  },
};

export const AboutTwoData = {
  sectionContent: {
    title: "We are the Best Website agency in The World",
    subTitle: "welcom to smart meipaly web agency",
    text:
      "We are committed to providing our customers with exceptional service while offering our employees the best training. Lorem Ipsum is simply dummy text of the printing and typesetting industry has been the industry's standard dummy text ever since the 1500s.",
  },
  button: {
    label: "Quero alta performance",
    url: "#",
  },
};

export const CallToActionTwoData = [
  {
    label: "View our Recent Work",
    url: "/portfolio",
  },
  {
    label: "Reqeust a free quote",
    url: "/contact",
  },
];

export const ClientCarouselData = {
  sectionContent: {
    title: "they trust us",
    subTitle: "our clients",
    text:
      "We are committed to providing our customers with exceptional service \n while offering our employees the best training.",
  },
  items: [
    {
      url: "#",
      image: ClientCarousel01,
    },
    {
      url: "#",
      image: ClientCarousel02,
    },
    {
      url: "#",
      image: ClientCarousel03,
    },
    {
      url: "#",
      image: ClientCarousel04,
    },
    {
      url: "#",
      image: ClientCarousel05,
    },
    {
      url: "#",
      image: ClientCarousel06,
    },
    {
      url: "#",
      image: ClientCarousel07,
    },
  ],
};